@tailwind base;
@tailwind components;
@tailwind utilities;

.react-p5 {
    /*display: grid;*/
    /*padding-top: 10px;*/
    display: flex;
    justify-content: space-around;
}

.button-btn {
    /*justify-items: center;*/
}

body {
    /*background-color: rgba(50, 48, 48, 0.9);*/
    background-color: black;
}

.frequency{
    bottom: 10%;
    right: 25%;
}